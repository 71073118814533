import { FunctionComponent, PropsWithChildren } from 'react';
import Head from 'next/head';
import { useViewport } from '../../hooks/useViewport';

const DEFAULT_IMAGE = `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/meta_default.jpg`;

export type BaseLayoutProps = PropsWithChildren<{
    title: string;
    description: string | null;
    path?: string;
    image?: string;
    themeColor?: string;
}>;

const BaseLayout: FunctionComponent<BaseLayoutProps> = ({
    title,
    description,
    path = '',
    image = DEFAULT_IMAGE,
    themeColor = '#ffffff',
    children,
}) => {
    const viewport = useViewport();

    return (
        <>
            <Head>
                <title>{title}</title>
                {description ? <meta name="description" content={description} /> : null}
                {description ? <meta name="og:description" content={description} /> : null}
                <meta property="og:title" content={title} />
                <meta property="og:url" content={`https://cademy.co.uk/${path}`} />
                <meta property="og:image" content={image} />
                <meta property="og:type" content="website" />
                <meta property="fb:app_id" content="439193920440220" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="theme-color" content={themeColor} />
                {viewport}
                <link rel="icon" href={`${process.env.NEXT_PUBLIC_ASSETS_URL}/logos/favicon.ico`} />
                <link
                    rel="apple-touch-icon"
                    href={`${process.env.NEXT_PUBLIC_ASSETS_URL}/logos/logo192.png`}
                />
                <link rel="manifest" href="/manifest.json" />
            </Head>
            {children}
        </>
    );
};

export default BaseLayout;
