import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import Link from 'next/link';
import { UnauthenticatedOnly } from '../../../../components/UnauthenticatedOnly';

export const MarketplaceShellFooter: FunctionComponent = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer_nav}>
                <div>
                    <span id="educators-footer-label">Platform</span>
                    <ul aria-labelledby="educators-footer-label">
                        <UnauthenticatedOnly>
                            <li>
                                <Link href="/login" rel="nofollow">
                                    Log in or Sign up
                                </Link>
                            </li>
                        </UnauthenticatedOnly>
                        <li>
                            <Link href="/for-educators">Create with Cademy</Link>
                        </li>
                        <li>
                            <Link href="/pricing">Creator Pricing</Link>
                        </li>
                        <li>
                            <Link href="/advertise">Advertise on Cademy</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <span id="browse-footer-label">Browse</span>
                    <ul aria-labelledby="browse-footer-label">
                        <li>
                            <Link href="/">Home</Link>
                        </li>
                        <li>
                            <Link href="/sitemap/popular-searches/1">Sitemap</Link>
                        </li>
                        <li>
                            <Link href="/add-educator" rel="nofollow">
                                Submit Missing Business
                            </Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <span id="company-footer-label">Company</span>
                    <ul aria-labelledby="company-footer-label">
                        <li>
                            <Link href="/about">About us</Link>
                        </li>
                        <li>
                            <Link href="https://roadmap.cademy.co.uk/changelog">
                                Product Updates
                            </Link>
                        </li>
                        <li>
                            <Link href="/privacy">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link href="/terms">Terms of Service</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <span id="contact-footer-label">Contact</span>
                    <ul aria-labelledby="contact-footer-label">
                        <li>
                            <Link href="/contact">Contact Us</Link>
                        </li>
                        <li>
                            <Link href="mailto:support@cademy.co.uk">Email</Link>
                        </li>
                        <li>
                            <Link href="https://twitter.com/cademy__">Twitter</Link>
                        </li>
                        <li>
                            <Link href="https://www.facebook.com/cademy.co.uk">Facebook</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.made_with_love}>
                <Link href="/about">Made with ❤️ in Edinburgh 🏴󠁧󠁢󠁳󠁣󠁴󠁿</Link>
            </div>
        </footer>
    );
};
